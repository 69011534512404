.page-title {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 110px 0 110px;
    min-height: 250px;
    background-image: url("../../public/assets/img/contact-us.jpg");
}

.page-title:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #131313;
    opacity: 0.6;
    content: "";
}

.page-title .title {
    font-size: 54px;
    color: #ffffff;
    margin-bottom: 17px;
    position: relative;
}

.page-breadcrumb li {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    padding-right: 13px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.contact-details__info {
  position: relative;
  display: block;
  margin-top: 41px;
}
.contact-details__info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-details__info li .icon {
  height: 80px;
  width: 80px;
  background-color:rgb(30, 115, 255);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin: 0px;
}
.contact-details__info li .icon span {
  color: #fff;
  font-size: 25px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li:hover .icon {
  background-color: var(--theme-color2);
}
.contact-details__info li:hover .icon span {
  color: var(--text-color-bg-theme-color2);
}
.contact-details__info li:hover .text a {
  color: var(--notech-base);
}
.contact-details__info li:hover .text a span {
  color: var(--notech-gray);
}
.contact-details__info li .text {
  margin-left: 30px;
}
.contact-details__info li .text p {
  font-size: 14px;
  line-height: 24px;
}
.contact-details__info li .text a {
  font-size: 18px;
  color: var(--notech-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .text span {
    font-size: 17px;
    color: var(--notech-black);
}
.contact-details__info li + li {
  margin-top: 19px;
}

.form-control, .input-text {
    height: calc(1.8rem + 27px);
    padding: 14px 20px;
    outline: 0;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    color: #686a6f;
    font-size: 0.9rem;
    width: 100%;
}